import React from 'react';

const SvgToday = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                d="M15.8 12.8H12V16.6H15.8V12.8ZM15 4.5V6H9.00002V4.5H7.50002V6H6.70001C5.90001 6 5.20001 6.7 5.20001 7.5V18C5.20001 18.8 5.90001 19.5 6.70001 19.5H17.2C18 19.5 18.7 18.8 18.7 18V7.5C18.7 6.7 18 6 17.2 6H16.4V4.5H15ZM17.2 18H6.80002V9.8H17.3V18H17.2Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgToday;
