import React from 'react';

const SvgDealsOutline = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g clipPath="url(#clip0_1711_187)" transform={`scale(${width / 24})`}>
            <path
                d="M12 4.5C7.86 4.5 4.5 7.86 4.5 12C4.5 16.14 7.86 19.5 12 19.5C16.14 19.5 19.5 16.14 19.5 12C19.5 7.86 16.14 4.5 12 4.5ZM12 18C8.6925 18 6 15.3075 6 12C6 8.6925 8.6925 6 12 6C15.3075 6 18 8.6925 18 12C18 15.3075 15.3075 18 12 18ZM12.2325 11.355C10.905 11.0175 10.4775 10.65 10.4775 10.1025C10.4775 9.4725 11.07 9.03 12.0525 9.03C13.0875 9.03 13.4775 9.525 13.5075 10.26H14.79C14.7525 9.255 14.1375 8.3325 12.9225 8.0325V6.75H11.175V8.0175C10.0425 8.2575 9.135 8.9925 9.135 10.125C9.135 11.4675 10.2525 12.1425 11.88 12.5325C13.3425 12.8775 13.635 13.395 13.635 13.935C13.635 14.3325 13.3425 14.9775 12.06 14.9775C10.86 14.9775 10.3875 14.4375 10.32 13.7475H9.03C9.105 15.0225 10.05 15.7425 11.175 15.975V17.25H12.93V15.9975C14.07 15.78 14.97 15.1275 14.9775 13.92C14.97 12.27 13.5525 11.7 12.2325 11.355Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_1711_187">
                <rect width={width} height={height} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgDealsOutline;
