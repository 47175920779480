import React from 'react';

const SvgTemplateOutline = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                d="M5.25 11.25V5.25H18.75V11.25H5.25ZM5.25 18.75V12.75H11.25V18.75H5.25ZM12.75 18.75V12.75H18.75V18.75H12.75ZM6.75 9.75H17.25V6.75H6.75V9.75ZM14.25 17.25H17.25V14.25H14.25V17.25ZM6.75 17.25H9.75V14.25H6.75V17.25Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgTemplateOutline;
