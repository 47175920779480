import React, {useState} from 'react';
import {composeClassName} from 'COMMON/helpers/css-classes';
import {ICONS_DICTIONARY} from '../dictionary';
import './icon.css';

/**
 * @typedef {keyof ICONS_DICTIONARY} IconValue
 */

/**
 * Icon component props
 * @typedef {object} IconProps
 * @property {keyof ICONS_DICTIONARY | string & {}} iconValue - Icon value that is used to choose svg react component
 * @property {string} [color] - Icon color
 * @property {string} [hoveredColor] - Hovered icon color
 * @property {string} [activeColor] - Active icon color
 * @property {('__blue')} [activeBackgroundColor] - Active icon background color
 * @property {Function} [onClick] - Click handler
 * @property {string} [className] - Custom class name
 * @property {boolean} [isActive] - Determines if the component is active
 * @property {boolean} [withBackground] - Determines if there is any background for the icon
 * @property {boolean} [borderedWhenActive] - Adds border when isActive equals to "true"
 * @property {boolean} [isBackgroundSquared] - Determines if background has squared form
 * @property {boolean} [isDisabled] - Determines if icon is disabled
 * @property {number} [width] - Determines width of svg icon
 * @property {number} [height] - Determines height of svg icon
 */

/**
 * Icon component
 * @param {IconProps} props - Icon Props
 * @returns {React.ReactElement} - Icon component
 */
const Icon = ({
    iconValue = 'graph',
    color = '#AABDCD',
    hoveredColor,
    activeColor,
    activeBackgroundColor = '__blue',
    onClick,
    className = '',
    isActive = false,
    withBackground = false,
    isBackgroundSquared = false,
    isDisabled = false,
    borderedWhenActive = false,
    width = 24,
    height = 24,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const getIconColor = () => {
        if (isActive) return activeColor;
        if (isHovered && hoveredColor && !isActive) return hoveredColor;
        return color;
    };

    /**
     * @param {React.MouseEvent<HTMLDivElement>} event - MouseEvent
     * @returns {void}
     */
    const clickHandler = (event) => {
        typeof onClick === 'function' && onClick(iconValue, event);
    };

    const onMouseEnter = () => {
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        setIsHovered(false);
    };

    const iconBackgroundClassNames = composeClassName({
        '__bordered': borderedWhenActive,
        '__active': isActive,
        '__squared-background': isBackgroundSquared,
        '__disabled': isDisabled,
        '__pointer': typeof onClick === 'function',
    }, 'svg-icon-background', className, activeBackgroundColor);

    const svgIconClassNames = composeClassName({
        '__disabled': isDisabled,
        '__pointer': typeof onClick === 'function',
    }, 'svg-icon', className);

    const Icon = typeof ICONS_DICTIONARY[iconValue] === 'function' ? ICONS_DICTIONARY[iconValue] : ICONS_DICTIONARY.pencil;

    return withBackground ? (
        <div
            data-testid={iconValue}
            tabIndex={0}
            role="button"
            className={iconBackgroundClassNames}
            onClick={clickHandler}
            onKeyDown={() => {}}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Icon color={getIconColor()} width={width} height={height} className="svg-icon" />
        </div>
    ) : (
        <Icon
            data-testid={iconValue}
            color={getIconColor()}
            width={width}
            height={height}
            className={svgIconClassNames}
            onClick={clickHandler}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    );
};

export default Icon;
