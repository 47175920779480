import React from 'react';

const SvgChart = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g clipPath="url(#clip0_1505_24)" transform={`scale(${width / 24})`}>
            <path
                d="M18.75 7.5C17.6625 7.5 17.055 8.58 17.3025 9.3825L14.64 12.0525C14.415 11.985 14.085 11.985 13.86 12.0525L11.9475 10.14C12.2025 9.3375 11.595 8.25 10.5 8.25C9.4125 8.25 8.7975 9.33 9.0525 10.14L5.6325 13.5525C4.83 13.305 3.75 13.9125 3.75 15C3.75 15.825 4.425 16.5 5.25 16.5C6.3375 16.5 6.945 15.42 6.6975 14.6175L10.11 11.1975C10.335 11.265 10.665 11.265 10.89 11.1975L12.8025 13.11C12.5475 13.9125 13.155 15 14.25 15C15.3375 15 15.9525 13.92 15.6975 13.11L18.3675 10.4475C19.17 10.695 20.25 10.0875 20.25 9C20.25 8.175 19.575 7.5 18.75 7.5Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_1505_24">
                <rect width={width} height={height} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgChart;
