import React from 'react';

const SvgSequenceOutline = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                d="M12.75 10V14.5H14.625V16H12.75V20.5H18V16H16.125V14.5H18V10H12.75ZM14.25 11.5H16.5V13H14.25V11.5ZM14.25 17.5H16.5V19H14.25V17.5ZM6 4V8.5H7.875V10H6V14.5H7.875V16H6V20.5H11.25V16H9.375V14.5H11.25V10H9.375V8.5H11.25V4H6ZM7.5 5.5H9.75V7H7.5V5.5ZM7.5 11.5H9.75V13H7.5V11.5ZM7.5 17.5H9.75V19H7.5V17.5Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgSequenceOutline;
