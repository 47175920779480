import React from 'react';

const SvgActivity = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g clipPath="url(#clip0_1497_75)" transform={`scale(${width / 24})`}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5 5H5.5C4.675 5 4 5.675 4 6.5V17C4 17.825 4.675 18.5 5.5 18.5H17.5C18.325 18.5 19 17.825 19 17V6.5C19 5.675 18.325 5 17.5 5ZM17.5 17H5.5V6.5H17.5V17Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0575 10.565L15.9925 9.5L13.615 11.8775L12.5575 10.8125L11.5 11.87L13.615 14L17.0575 10.565Z"
                fill={color}
            />
            <path d="M10 8H6.25V9.5H10V8Z" fill={color} />
            <path d="M10 11H6.25V12.5H10V11Z" fill={color} />
            <path d="M10 14H6.25V15.5H10V14Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1497_75">
                <rect width={width} height={height} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgActivity;
