import React from 'react';

const SvgEducation = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M6.8 12.9v3l5.2 2.9 5.3-2.9v-3L12 15.8l-5.2-2.9ZM12 5.3 3.8 9.8l8.2 4.5 6.8-3.7v5.2h1.5v-6L12 5.3Z"
            />
        </g>
    </svg>
);

export default SvgEducation;
