import React from 'react';

const SvgForm = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g transform={`scale(${width / 24})`}>
            <path
                d="M6.75 19.5C6.3375 19.5 5.98438 19.3531 5.69063 19.0594C5.39687 18.7656 5.25 18.4125 5.25 18V7.5C5.25 7.0875 5.39687 6.73438 5.69063 6.44063C5.98438 6.14688 6.3375 6 6.75 6H9.9C10.0625 5.55 10.3344 5.1875 10.7156 4.9125C11.0969 4.6375 11.525 4.5 12 4.5C12.475 4.5 12.9031 4.6375 13.2844 4.9125C13.6656 5.1875 13.9375 5.55 14.1 6H17.25C17.6625 6 18.0156 6.14688 18.3094 6.44063C18.6031 6.73438 18.75 7.0875 18.75 7.5V18C18.75 18.4125 18.6031 18.7656 18.3094 19.0594C18.0156 19.3531 17.6625 19.5 17.25 19.5H6.75ZM6.75 18H17.25V7.5H6.75V18ZM8.25 16.5H13.5V15H8.25V16.5ZM8.25 13.5H15.75V12H8.25V13.5ZM8.25 10.5H15.75V9H8.25V10.5ZM12 6.9375C12.1625 6.9375 12.2969 6.88438 12.4031 6.77813C12.5094 6.67188 12.5625 6.5375 12.5625 6.375C12.5625 6.2125 12.5094 6.07813 12.4031 5.97188C12.2969 5.86563 12.1625 5.8125 12 5.8125C11.8375 5.8125 11.7031 5.86563 11.5969 5.97188C11.4906 6.07813 11.4375 6.2125 11.4375 6.375C11.4375 6.5375 11.4906 6.67188 11.5969 6.77813C11.7031 6.88438 11.8375 6.9375 12 6.9375Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SvgForm;
