import React from 'react';

const SvgHelp = ({color, width = 24, height = 24, ...props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
        <g clipPath="url(#help_svg__a)" transform={`scale(${width / 24})`}>
            <path
                fill={color}
                d="M11.625 20.471v-2.7a7.134 7.134 0 0 1-6.75-7.117A7.131 7.131 0 0 1 12 3.529a7.131 7.131 0 0 1 7.125 7.125c0 3.712-2.58 7.447-6.428 9.3l-1.072.517ZM12 5.03a5.627 5.627 0 0 0-5.625 5.625A5.627 5.627 0 0 0 12 16.279h1.125v1.725c2.73-1.725 4.5-4.56 4.5-7.35A5.627 5.627 0 0 0 12 5.029Zm-.75 8.625h1.5v1.5h-1.5v-1.5Zm1.5-1.125h-1.5c0-2.438 2.25-2.25 2.25-3.75 0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5H9a3 3 0 1 1 6 0c0 1.875-2.25 2.062-2.25 3.75Z"
            />
        </g>
        <defs>
            <clipPath id="help_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgHelp;
